import * as React from "react"
import { Link, graphql, PageProps } from "gatsby"
import * as styles from "./post-details.module.scss"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Newsletter from "../../components/common/newsletter"
import Categories from "../../components/blog/categories"
import Button from "../../components/common/button"
import Header from "../../components/common/pageHeader"
import SocialIcons from "../../components/blog/socialIcons"
import PostHeroBg from "../../images/post-hero.png";
import PostLeftDeco from "../../images/post-deco-left.png";
import PostRightDeco from "../../images/post-deco-right.png";
import PostImageLeftDeco from "../../images/post-image-deco-left.png";
import PostImageRightDeco from "../../images/post-image-deco-right.png";
import LatestPosts from "../../components/blog/latest-posts"
import { isBrowser } from "../../utils"

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      post (id: $id) {
        date
        slug
        uri
        title(format: RENDERED)
        excerpt(format: RENDERED)
        content(format: RENDERED)
        author {
          node {
            avatar {
              url
            }
            name
            description
          }
        }
        categories (first: 1000) {
          nodes {
            id
            name
            slug
          }
        }
        featuredImage {
          node {
            altText
            sourceUrl(size: LARGE)
          }
        }
      }
    }
  }
`
const blogDetails: React.FC<PageProps<DataProps>> = ({ data }) => {
  let post: postType = data.wpgraphql.post;
  const [socialVisible, setSocialVisible] = React.useState(false)
  // const getMonth = (number) => {
  //   return ['Jan', "Feb", "March", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"][number]
  // }
  const isInViewport = (element) => {
    if(!isBrowser){
      return;
    }
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
  React.useEffect(() => {
    if(!isBrowser){
      return;
    }
    let socialIcons = document.getElementById("socialIcons");
    window.onscroll = () => { setSocialVisible(isInViewport(socialIcons)) }
  }, [])

  return (
    <Layout>
      <Seo title={post.title} description={post.excerpt} image={post.featuredImage.node.sourceUrl} article path={post.uri} />
      <div className="relative">
        {/* Decorative items */}
        <img src={PostLeftDeco} className={`${styles.postLeftDeco}  absolute left-0 z-0`} />
        <img src={PostRightDeco} className={`${styles.postRightDeco}   absolute z-0`} />
        {/* ------------------ */}
        <img src={PostHeroBg} className="absolute top-0 left-0 right-0 w-full z-0" alt="post background decoration item" />
        <div className={`${styles.postDetails} container mx-auto px-6 lg:px-24 relative z-10 pb-8 lg:pb-16 overflow-x-hidden`}>
          <div className="pt-8 lg:pt-16 pb-5 lg:pb-10">
            <Link to="/">
              <Button data={{ text: "Back to blog" }} buttonType="primary" accent="Transparent" type="button" arrowLeft />
            </Link>
          </div>
          <div className={`${styles.topHeaderWrapper} flex justify-center`}>
            <div className={styles.topHeader}>
              <Categories data={post.categories.nodes} />
              <Header topHeader layout="left" data={{ tag: "Learn to interview", category: { name: post.title } }} />
              {/* Author below */}
              {/* <div className="flex items-start">
                <img src={post.author.node.avatar.url} alt="Author image" className="rounded-full" />
                <div className={`${styles.authDetails} flex flex-col justify-center`}>
                  <p className="font-normal mb-1">{post.author.node.name}</p>
                  <p className={`${styles.authDate} text-xs`}><span>{new Date(post.date).getDate()}</span>{" "}<span>{getMonth(new Date(post.date).getMonth())},{" "}</span><span>{new Date(post.date).getFullYear()}</span></p>
                </div>
              </div> */}
            </div>
          </div>
          <div className={`${styles.featuredImage} relative`}>
            <img src={PostImageLeftDeco} className={`${styles.postImageLeftDeco} hidden lg:block absolute z-0`} />
            <img src={PostImageRightDeco} className={`${styles.postImageRightDeco}  absolute z-0`} />
            <img className="mx-auto relative z-10" src={post.featuredImage.node.sourceUrl} alt={post.featuredImage.node.altText} />
          </div>
          <div className="flex justify-center relative">
            <img src={PostImageLeftDeco} className={`${styles.postImageMid} hidden lg:block  absolute z-0`} />
            <div className={`${styles.postContent}`}>
              <div className={`${styles.content}`} dangerouslySetInnerHTML={{ __html: post.content }} />
              {/* <div className={`${styles.authCardWrapper}`}>
                <div className="flex flex-col lg:flex-row items-start">
                  <img className="rounded-full" src={post.author.node.avatar.url} alt="Author image" />
                  <div className="pt-5 lg:pt-0 lg:pl-4">
                    <p className="font-normal mb-1 title">{post.author.node.name}</p>
                    <p className={`${styles.authDis} text-xs`}>{post.author.node.description}</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className={`${styles.horizontalRule} w-full`} />
          <div className={`${styles.socialWrapper} flex flex-col justify-center items-center`}>
            <p className="my-6">Like this article?</p>
            <div id="socialIcons">
              <SocialIcons post={post} orientation="horizontal" />
            </div>
          </div>
        </div>
        {/* Fixed icons on left, only show when others are not visible */}
        {!socialVisible && <SocialIcons post={post} isFixed orientation="vertical" />}
      </div>
      <LatestPosts />
      <Newsletter
        data={{
          tag: "Newsletter",
          title: "Subscribe to our newsletter",
        }}
      />
    </Layout>
  )
}

export default blogDetails
type DataProps = {
  wpgraphql: {
    post: postType
  }
}
export type postType = {
  date: string
  slug: string
  uri: string
  title: string
  content: string
  excerpt: string
  categories: { nodes: [] }
  author: {
    node: {
      avatar: {
        url: string
      }
      name: string
      description: string
    }
  }
  featuredImage: {
    node: {
      altText: string
      sourceUrl: string
    }
  }
}
