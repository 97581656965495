// extracted by mini-css-extract-plugin
export var searchExpand = "post-details-module--search-expand--g3q1z";
export var expand = "post-details-module--expand--9yjs1";
export var searchCollapse = "post-details-module--search-collapse--cQktH";
export var postLeftDeco = "post-details-module--postLeftDeco--xEEHk";
export var postRightDeco = "post-details-module--postRightDeco--cCGK5";
export var postImageMid = "post-details-module--postImageMid--aQ9U8";
export var topHeaderWrapper = "post-details-module--topHeaderWrapper--Nq-e+";
export var topHeader = "post-details-module--topHeader--6LVUc";
export var authDetails = "post-details-module--authDetails--aiTUx";
export var authDate = "post-details-module--authDate--sUaSu";
export var postDetails = "post-details-module--postDetails--TsXwC";
export var featuredImage = "post-details-module--featuredImage--ls9Dj";
export var postImageLeftDeco = "post-details-module--postImageLeftDeco--EwdNb";
export var postImageRightDeco = "post-details-module--postImageRightDeco--M2HKE";
export var postContent = "post-details-module--postContent--K0-S7";
export var content = "post-details-module--content--Fewdb";
export var authCardWrapper = "post-details-module--authCardWrapper--pDLZZ";
export var authDis = "post-details-module--authDis--E80y3";
export var horizontalRule = "post-details-module--horizontalRule--03NpZ";
export var socialWrapper = "post-details-module--socialWrapper--vA2CW";